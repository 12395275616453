<template>
  <div>
    <div class="card-body" :class="{'was-validated': $v.value.$dirty}">
      <template v-if="edit">
        <div class="form-group row d-flex">
          <label class="col-sm-3 col-form-label text-right font-weight-bold">Upload Photo</label>
          <div class="col-sm-7">
            <image-input
              v-model="$v.value.photo.$model"
              :tags="['article requisition']"
              :thumbnail-options="{height: 120, width: 120, crop: 'fill'}"
              class="avatar avatar-xxl"
              @error="handleImageError"></image-input>
          </div>
        </div>

        <div class="form-group row d-flex">
          <label class="col-sm-3 col-form-label text-right font-weight-bold">Ingredient <span class="text-danger">*</span></label>
          <div class="col-sm-7">
            <autocomplete
              v-model="$v.value.ingredient.$model"
              :list.sync="ingredients"
              :nextPage="nextIngredients"
              :class="{'is-invalid': $v.value.ingredient.$error}"
              label="name"
              entity="ingredient"
            >
            </autocomplete>
            <template v-if="$v.value.ingredient.$dirty">
              <div v-if="!$v.value.ingredient.required" class="invalid-feedback d-block">Ingredient is required</div>
              <div v-if="!$v.value.ingredient.apiError" class="invalid-feedback d-block">{{errors.ingredient.join(' ')}}</div>
            </template>
          </div>
        </div>

        <div class="form-group row d-flex">
          <label class="col-sm-3 col-form-label text-right font-weight-bold">Brand</label>
          <div class="col-sm-7">
            <autocomplete
              :list.sync="brands"
              :nextPage="nextBrands"
              placeholder="Select brand"
              :class="{'is-invalid': $v.value.brand.$error}"
              :value="value.brand"
              label="name"
              entity="brand"
              @input="value => updateBrand(value)"></autocomplete>
            <template v-if="$v.value.brand.$dirty">
              <div v-if="!$v.value.brand.required" class="invalid-feedback d-block">Brand is required. If it's not present in this list, mention it below.</div>
            </template>
          </div>
        </div>

        <div class="form-group row d-flex">
          <label class="col-sm-3 col-form-label text-right font-weight-bold">Suggest A New Brand</label>
          <div class="col-sm-7">
            <input
              v-model="$v.value.custom_brand_name.$model"
              type="text"
              placeholder="Enter manually if it's not available in the drop-down above"
              class="form-control"/>
          </div>
        </div>

        <div class="form-group row d-flex">
          <label class="col-sm-3 col-form-label text-right font-weight-bold">Article Portion Size <span class="text-danger">*</span></label>
          <div class="col-sm-7">
            <input
              v-model="$v.value.article_portion_size.$model"
              type="number"
              placeholder="Enter a number"
              class="form-control"
              :class="{'is-invalid': $v.value.article_portion_size.$error}"/>
            <template v-if="$v.value.article_portion_size.$dirty">
              <div v-if="!$v.value.article_portion_size.required" class="invalid-feedback d-block">This field is required</div>
              <div v-if="!$v.value.article_portion_size.apiError" class="invalid-feedback d-block">{{errors.article_portion_size.join(' ')}}</div>
            </template>
          </div>
        </div>

        <div class="form-group row d-flex">
          <label class="col-sm-3 col-form-label text-right font-weight-bold">Article UOM <span class="text-danger">*</span></label>
          <div class="col-sm-7">
            <select
              v-model="$v.value.article_uom.$model"
              class="form-control custom-select"
              :class="{'is-invalid': $v.value.article_uom.$error}">
              <option value="">Select UOM</option>
              <option value="Piece">Piece</option>
              <option value="Grams">Grams</option>
              <option value="ML">ML</option>
            </select>
            <template v-if="$v.value.article_uom.$dirty">
              <div v-if="!$v.value.article_uom.required" class="invalid-feedback d-block">This field is required</div>
              <div v-if="!$v.value.article_uom.apiError" class="invalid-feedback d-block">{{errors.article_uom.join(' ')}}</div>
            </template>
          </div>
        </div>

        <div class="form-group row d-flex">
          <label class="col-sm-3 col-form-label text-right font-weight-bold">Estd. Weekly Consumption <span class="text-danger">*</span></label>
          <div class="col-sm-7">
            <input
              v-model="$v.value.article_estimated_weekly_consumption.$model"
              type="number"
              placeholder="Enter a number"
              class="form-control"
              :class="{'is-invalid': $v.value.article_estimated_weekly_consumption.$error}"/>
            <template v-if="$v.value.article_estimated_weekly_consumption.$dirty">
              <div v-if="!$v.value.article_estimated_weekly_consumption.required" class="invalid-feedback d-block">This field is required</div>
              <div v-if="!$v.value.article_estimated_weekly_consumption.apiError" class="invalid-feedback d-block">{{errors.article_estimated_weekly_consumption.join(' ')}}</div>
            </template>
          </div>
        </div>

        <div class="form-group row d-flex">
          <label class="col-sm-3 col-form-label text-right font-weight-bold">Article Price <span class="text-danger">*</span></label>
          <div class="col-sm-8">
            <div class="row">
              <div class="col-5">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">From</span>
                  </div>
                  <input
                    v-model="$v.value.article_price_per_unit_from.$model"
                    type="number"
                    step="any"
                    class="form-control"
                    :class="{'is-invalid': $v.value.article_price_per_unit_from.$error}"
                    placeholder="00.00">
                  <div class="input-group-append">
                    <span class="input-group-text">AED</span>
                  </div>
                </div>
                <template v-if="$v.value.article_price_per_unit_from.$dirty">
                  <div v-if="!$v.value.article_price_per_unit_from.required" class="invalid-feedback d-block">This field is required</div>
                </template>
              </div>
              <div class="col-5 pl-0">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">To</span>
                  </div>
                  <input
                    v-model="$v.value.article_price_per_unit_to.$model"
                    type="number"
                    step="any"
                    class="form-control"
                    :class="{'is-invalid': $v.value.article_price_per_unit_to.$error}"
                    placeholder="99.99">
                  <div class="input-group-append">
                    <span class="input-group-text">AED</span>
                  </div>
                </div>
                <template v-if="$v.value.article_price_per_unit_to.$dirty">
                  <div v-if="!$v.value.article_price_per_unit_to.required" class="invalid-feedback d-block">This field is required</div>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row d-flex">
          <label class="col-sm-3 col-form-label text-right font-weight-bold">Article Features</label>
          <div class="col-sm-7">
            <input
              v-model="$v.value.article_features.$model"
              type="text"
              placeholder="Separate features by comma"
              class="form-control"
              :class="{'is-invalid': $v.value.article_features.$error}"/>
          </div>
        </div>

        <div class="form-group row d-flex">
          <label class="col-sm-3 col-form-label text-right font-weight-bold">Article Should Not Have Allergens</label>
          <div class="col-sm-7">
            <input
              v-model="$v.value.article_should_not_have_allergens.$model"
              type="text"
              placeholder="Separate allergens by comma"
              class="form-control"
              :class="{'is-invalid': $v.value.article_should_not_have_allergens.$error}"/>
          </div>
        </div>

        <div class="form-group row d-flex">
          <label class="col-sm-3 col-form-label text-right font-weight-bold">Other Info</label>
          <div class="col-sm-7">
            <textarea
              v-model="$v.value.other_info.$model"
              type="text"
              placeholder="Any additional information for the procurement team"
              class="form-control"
              :class="{'is-invalid': $v.value.other_info.$error}">
            </textarea>
          </div>
        </div>
      </template>

      <template v-else>
        <div v-if="value.photo" class="row d-flex photo">
          <label class="text-right font-weight-bold col-sm-5">Photo:</label>
          <div class="col-sm-7">
            <img
              class="photo-image"
              :src="value.photo.url"
              alt="Requisition"
            />

            <span class="small">To expand, right click and open in a new tab.</span>
          </div>
        </div>
        <form-description>
          <label class="text-right font-weight-bold col-sm-5">Status:</label>
          <div class="col-sm-7">
            <div v-if="ArticleRequisitionStatus[value.status]">
              <i class="fe fe-circle rounded-circle mr-1" :class="ArticleRequisitionStatus[value.status].dotClass"></i>
              {{value.status}}
            </div>
            <div v-else>
              <i class="fe fe-circle rounded-circle b mr-1" :class="ArticleRequisitionStatus['default'].dotClass"></i>
              {{upperFirst(value.status)}}
            </div>
          </div>
        </form-description>
        <form-description label="Ingredient" :value="value.ingredient.name"/>
        <form-description
          v-if="value.brand"
          label="Brand"
          :value="value.brand.name"
        />
        <form-description
          v-if="value.custom_brand_name"
          label="Custom Brand"
          :value="value.custom_brand_name"
        />
        <form-description label="Article Portion Size" :value="`${value.article_portion_size} ${value.article_uom}`"/>
        <form-description label="Estimated Weekly Consumption" :value="value.article_estimated_weekly_consumption"/>
        <form-description label="Article Price Range Per Unit" :value="`AED ${value.article_price_per_unit_from} to AED ${value.article_price_per_unit_to}`"/>
        <form-description
          v-if="value.article_features.length > 0"
          label="Features"
          :value="value.article_features"
        />
        <form-description
          v-if="value.article_should_not_have_allergens.length > 0"
          label="Should Not Have These Allergens"
          :value="value.article_should_not_have_allergens"
        />
        <form-description
          v-if="value.other_info"
          label="Other Info"
          :value="value.other_info"
        />
        <form-description label="Requested By" :value="value.requested_by_name"/>
        <form-description label="When" :value="moment.utc(value.created_at).local().fromNow()"/>
      </template>
    </div>
  </div>
</template>

<script>
import Autocomplete from '@/components/Autocomplete';
import ArticleRequisitionStatus from '@/assets/enums/ArticleRequisitionStatus';
import {brands, mainIngredients} from '@/services';
import FormDescription from '@/components/FormDescription';
import ImageInput from '@/components/ImageInput';
import {required, requiredIf} from 'vuelidate/lib/validators';

export default {
  components: {
    Autocomplete,
    FormDescription,
    ImageInput,
  },
  props: {
    edit: {
      default: false,
      type: Boolean,
    },
    errors: {
      default: () => {},
      type: Object,
    },
    isNew: {
      require: true,
      type: Boolean,
    },
    old: {
      default: () => {},
      type: Object,
    },
    value: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      ArticleRequisitionStatus,
      brands: [],
      ingredients: [],
    };
  },
  created() {
    brands.getByParameters({column: 'name', direction: 'asc'}).then(result => this.brands = result);
    mainIngredients.getByParameters({column: 'name', direction: 'asc'}).then(result => this.ingredients = result);
  },
  methods: {
    async nextBrands(page, query) {
      const params = {
        column: 'name',
        direction: 'asc',
        page,
      };

      if (query.length) params.query = query;
      const result = await brands.getByParameters(params);
      return result;
    },
    async nextIngredients(page, query) {
      const params = {
        column: 'name',
        direction: 'asc',
        page,
      };

      if (query.length) {
        params.query = query;
        delete params.column;
        delete params.direction;
      }

      const result = await mainIngredients.getByParameters(params);
      return result;
    },
    updateBrand(value) {
      this.value.brand = value;
      this.$emit('update-names');
    },
    handleImageError(ex) {
      this.invalid = true;
      this.invalidMessage = ex.message;
    },
    validate() {
      this.$v.value.$touch();
      if (!this.$v.value.$invalid) { this.$emit('valid'); }
    },
  },
  validations: {
    value: {
      brand: {
        required: requiredIf(function(value) {
          return !this.value.custom_brand_name;
        }),
        type: Object,
      },
      custom_brand_name: {
        required: requiredIf(function(value) {
          return !this.value.brand?.id;
        }),
        type: String,
      },
      ingredient: {
        required,
        type: Object,
        apiError: function(value) {
          return !(this.errors?.ingredient && this.old?.ingredient === value);
        },
      },
      article_uom: {
        required,
        type: String,
        apiError: function(value) {
          return !(this.errors?.article_uom && this.old?.article_uom === value);
        },
      },
      article_portion_size: {
        required,
        type: Number,
        apiError: function(value) {
          return !(this.errors?.article_portion_size && this.old?.article_portion_size === value);
        },
      },
      other_info: {
        required: false,
        type: String,
      },
      photo: {
        required: false,
        type: String,
      },
      article_price_per_unit_from: {
        required,
        type: Number,
        apiError: function(value) {
          return !(this.errors?.article_price_per_unit_from && this.old?.article_price_per_unit_from === value);
        },
      },
      article_price_per_unit_to: {
        required,
        type: Number,
        apiError: function(value) {
          return !(this.errors?.article_price_per_unit_to && this.old?.article_price_per_unit_to === value);
        },
      },
      article_estimated_weekly_consumption: {
        required,
        type: Number,
        apiError: function(value) {
          return !(this.errors?.article_estimated_weekly_consumption && this.old?.article_estimated_weekly_consumption === value);
        },
      },
      article_features: {
        required: false,
        type: String,
      },
      article_should_not_have_allergens: {
        required: false,
        type: String,
      },
      article_data: {
        required: false,
        type: Object,
      },
    },
  },
};
</script>

<style scoped>
  .photo {
    margin-bottom: 0.5rem;
  }

  .photo label {
    align-self: center;
  }

  .photo img {
    display: block;
    width: 20rem;
    max-width: 100%;
    border-radius: 0.25rem;
  }

  .photo .small {
    display: block;
    font-size: 0.75rem;
    font-style: italic;
    color: var(--gray);
  }
</style>
